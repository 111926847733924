@font-face {
  font-family: 'A-OTF Shin Go Pro L';
  font-style: normal;
  font-weight: normal;
  src: local("A-OTF Shin Go Pro L"), url("https://1380702123.rsc.cdn77.org/fonts/AOTFShinGoProLight.woff") format("woff"); }

@font-face {
  font-family: 'A-OTF Shin Go Pro R';
  font-style: normal;
  font-weight: normal;
  src: local("A-OTF Shin Go Pro R"), url("https://1380702123.rsc.cdn77.org/fonts/AOTFShinGoProRegular.woff") format("woff"); }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

ul {
  list-style-type: none; }

a {
  color: inherit;
  text-decoration: none; }

ul,
ol,
div {
  margin: 0;
  padding: 0; }

input,
textarea {
  font-family: "A-OTF Shin Go Pro L", sans-serif; }

html {
  font-family: "A-OTF Shin Go Pro L", sans-serif;
  color: #949494;
  font-size: 10px;
  -webkit-font-feature-settings: 'palt';
          font-feature-settings: 'palt';
  font-weight: 300; }
  @media screen and (max-width: 1040px) {
    html {
      min-width: 1040px; } }
  @media screen and (max-width: 767px) {
    html {
      min-width: 100%; } }

body {
  padding-top: 220px; }
  @media screen and (max-width: 767px) {
    body {
      padding-top: 80px; } }

h4 {
  font-size: 48px; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 28px; } }

ol {
  list-style: none;
  counter-reset: no-space-counter; }
  ol li {
    counter-increment: no-space-counter;
    text-indent: -17px;
    padding-left: 17px; }
    @media screen and (max-width: 767px) {
      ol li {
        text-indent: -14px;
        padding-left: 14px; } }
    ol li::before {
      content: counter(no-space-counter) "."; }

ul li {
  position: relative;
  padding-left: 19px; }
  @media screen and (max-width: 767px) {
    ul li {
      padding-left: 15px; } }
  ul li::before {
    content: '';
    position: absolute;
    width: 12.44px;
    height: 12.44px;
    left: 0;
    top: 10px;
    background-color: #fff;
    border-radius: 50%; }
    @media screen and (max-width: 767px) {
      ul li::before {
        width: 10px;
        height: 10px;
        top: 8px; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999; }
  .header__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1040px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media screen and (max-width: 1100px) {
      .header__container {
        padding: 0 3%; } }
    @media screen and (max-width: 767px) {
      .header__container {
        max-width: 100%;
        padding: 0 6%; } }
    @media screen and (max-width: 767px) {
      .header__container {
        padding: 0; } }
  .header__logo {
    width: 133.67px;
    height: 133.67px;
    background-color: #fff;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .header__logo {
        width: 60px;
        height: 60px;
        padding: 10px; } }
  @media screen and (max-width: 767px) {
    .header__logo-icon {
      max-width: 100%;
      max-height: 100%; } }
  .header__menu {
    display: none; }
    @media screen and (max-width: 767px) {
      .header__menu {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        width: 30px;
        height: 30px;
        margin-right: 3%; } }
  .header__line {
    width: 100%;
    height: 5px;
    background-color: #949494;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    .header__line:nth-child(2) {
      width: 80%; }
    .header__line:nth-child(3) {
      width: 60%; }
  .header__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 767px) {
      .header__content--left {
        width: 100%;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        z-index: 999;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); } }
    @media screen and (max-width: 767px) {
      .header__content--right {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: 0.3s ease;
        -o-transition: 0.3s ease;
        transition: 0.3s ease; } }
  .header__navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 105px 56px 0 0; }
    @media screen and (max-width: 767px) {
      .header__navigation {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        background-color: #fff;
        width: 100%;
        margin-top: 60px;
        overflow: scroll; } }
  .header__link {
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    @media screen and (max-width: 767px) {
      .header__link {
        padding: 15px 0 10px;
        text-align: center;
        border-bottom: 1px solid #949494; } }
    .header__link:not(:last-child) {
      margin-right: 56px; }
      @media screen and (max-width: 767px) {
        .header__link:not(:last-child) {
          margin-right: 0; } }
  .header__link-text {
    fill: #949494;
    width: 27.76px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    .header__link-text--about {
      width: 50.82px; }
    .header__link-text--solution {
      width: 69.12px; }
    .header__link-text--contact {
      width: 62.38px; }
    .header__link-text--online {
      width: 53.12px; }
  .header__socials {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 105px; }
    @media screen and (max-width: 767px) {
      .header__socials {
        margin-top: 0;
        background-color: #fff;
        padding: 10px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center; } }
  .header__socials-link:not(:last-child) {
    margin-right: 16px; }
  .header--active .header__line:nth-child(1) {
    -webkit-transform: rotate(-45deg) translate(-10px, 8px);
        -ms-transform: rotate(-45deg) translate(-10px, 8px);
            transform: rotate(-45deg) translate(-10px, 8px); }
  .header--active .header__line:nth-child(2) {
    opacity: 0; }
  .header--active .header__line:nth-child(3) {
    width: 100%;
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
        -ms-transform: rotate(45deg) translate(-9px, -8px);
            transform: rotate(45deg) translate(-9px, -8px); }
  .header--active .header__content--right {
    max-height: 100vh; }

.banner {
  margin-bottom: 383.98px; }
  @media screen and (max-width: 767px) {
    .banner {
      margin-bottom: 100px; } }
  .banner__svg {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .banner__svg {
        width: 88%;
        margin: 0 auto;
        display: block; } }
    .banner__svg .cls-1 {
      -webkit-transition: 0.3s ease;
      -o-transition: 0.3s ease;
      transition: 0.3s ease;
      fill: #fff; }

.section {
  padding-top: 85.92px;
  margin-bottom: 250px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .section {
      padding-top: 50px;
      margin-bottom: 100px; } }
  .section__content {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media screen and (max-width: 1100px) {
      .section__content {
        padding: 0 3%; } }
    @media screen and (max-width: 767px) {
      .section__content {
        max-width: 100%;
        padding: 0 6%; } }
  .section__title {
    fill: #fff;
    margin-bottom: 47.26px;
    height: 85.2px; }
    @media screen and (max-width: 767px) {
      .section__title {
        height: 45.2px;
        margin-bottom: 20px; } }
  .section__text {
    font-size: 15px;
    line-height: 2em;
    letter-spacing: 0.34em;
    font-weight: 300; }
    @media screen and (max-width: 767px) {
      .section__text {
        font-size: 12px; } }
  .section__paragraph:not(:last-child) {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .section__paragraph:not(:last-child) {
        margin-bottom: 15px; } }
  .section__svg {
    position: absolute;
    width: 863px;
    top: -9.08px;
    left: 355px;
    fill: #f2f8fc;
    z-index: -1; }
    @media screen and (max-width: 767px) {
      .section__svg {
        width: 300px;
        left: auto;
        right: 10px; } }
  .section__regular {
    font-family: "A-OTF Shin Go Pro R", sans-serif;
    font-weight: 400; }

.will__action {
  margin-top: 115.48px; }
  @media screen and (max-width: 767px) {
    .will__action {
      margin-top: 50px; } }

.will__action-title {
  fill: #fff;
  margin-bottom: 37.97px;
  width: 136.63px; }
  @media screen and (max-width: 767px) {
    .will__action-title {
      margin-bottom: 20px;
      width: 106.63px; } }

.will__action-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 767px) {
    .will__action-list {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }

.will__action-svg {
  fill: #d0d0d0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease; }
  @media screen and (max-width: 767px) {
    .will__action-svg {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .will__action-item {
    width: 15%;
    max-width: 80px; } }

.will__action-item:not(:last-child) {
  margin-right: 55.45px; }
  @media screen and (max-width: 767px) {
    .will__action-item:not(:last-child) {
      margin-right: 0; } }

.will__action-item:hover .will__action-svg {
  fill: #a7cdea; }
  @media screen and (max-width: 767px) {
    .will__action-item:hover .will__action-svg {
      fill: #d0d0d0; } }

.will__action-item--active .will__action-svg {
  fill: #a7cdea !important; }

.will__action-info-block {
  display: none;
  position: absolute;
  top: 56.8px;
  width: 1085px;
  height: 550px;
  right: 50%;
  -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
          transform: translateX(50%);
  background-color: #fff;
  -webkit-box-shadow: 7px 7px 10px rgba(202, 202, 202, 0.5);
          box-shadow: 7px 7px 10px rgba(202, 202, 202, 0.5);
  z-index: 2;
  padding: 83.47px 75.26px 75px; }
  @media screen and (max-width: 767px) {
    .will__action-info-block {
      width: 94%;
      padding: 20px 6% 15px;
      top: 15px;
      height: 390px; } }
  .will__action-info-block::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: 0 0 10px rgba(202, 202, 202, 0.5);
            box-shadow: 0 0 10px rgba(202, 202, 202, 0.5);
    z-index: 1; }
  .will__action-info-block--active {
    display: block; }

.will__action-info-content {
  position: relative;
  z-index: 1; }

.will__action-text {
  width: 129.67px;
  margin-bottom: 1px; }
  @media screen and (max-width: 767px) {
    .will__action-text {
      font-size: 24px;
      width: 79.67px; } }
  .will__action-text--2 {
    width: 136.91px; }
    @media screen and (max-width: 767px) {
      .will__action-text--2 {
        width: 86.91px; } }
  .will__action-text--3 {
    width: 136.41px; }
    @media screen and (max-width: 767px) {
      .will__action-text--3 {
        width: 86.41px; } }
  .will__action-text--4 {
    width: 137.55px; }
    @media screen and (max-width: 767px) {
      .will__action-text--4 {
        width: 87.55px; } }
  .will__action-text--5 {
    width: 136.26px; }
    @media screen and (max-width: 767px) {
      .will__action-text--5 {
        width: 86.26px; } }

.will__action-info-title {
  font-weight: 300;
  letter-spacing: 0.21em;
  line-height: 1.20833em;
  margin-bottom: 24.56px;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .will__action-info-title {
      margin-bottom: 15px;
      letter-spacing: 0.1em; } }

.will__action-info-text {
  letter-spacing: 0.2em; }

.will__action-info-svg {
  position: absolute;
  right: 58.75px;
  bottom: 79.89px;
  fill: #a7cdea;
  stroke: #a7cdea;
  stroke-miterlimit: 10;
  z-index: -1; }
  @media screen and (max-width: 767px) {
    .will__action-info-svg {
      position: static;
      width: 100%;
      max-height: 35%;
      margin-top: 15px; } }
  .will__action-info-svg--action2 {
    right: 117.45px;
    bottom: 116.88px; }
  .will__action-info-svg--action3 {
    right: 182.55px;
    bottom: 46px; }
  .will__action-info-svg--action4 {
    right: 72.44px;
    bottom: 51.43px; }
  .will__action-info-svg--action5 {
    right: 119px;
    bottom: 43.83px; }

.about {
  padding-bottom: 107px;
  margin-bottom: 276.98px; }
  @media screen and (max-width: 767px) {
    .about {
      padding-bottom: 0;
      margin-bottom: 100px; } }
  .about__info {
    margin-left: auto;
    width: 515px; }
    @media screen and (max-width: 767px) {
      .about__info {
        width: 100%; } }
  .about__svg {
    width: 1005.55px;
    left: -238px;
    top: 77.58px; }
    @media screen and (max-width: 767px) {
      .about__svg {
        width: 400px;
        left: -20px;
        top: 70px; } }
  .about__text {
    letter-spacing: 0.2em; }

.solution__title {
  height: 67.16px; }
  @media screen and (max-width: 767px) {
    .solution__title {
      height: 40px; } }

.solution__title-svg {
  height: 53.83px;
  fill: #fff; }
  @media screen and (max-width: 767px) {
    .solution__title-svg {
      height: 33.83px; } }
  .solution__title-svg--web {
    height: 41.18px; }
    @media screen and (max-width: 767px) {
      .solution__title-svg--web {
        height: 21.18px; } }
  .solution__title-svg--promotion {
    height: 50.23px; }
    @media screen and (max-width: 767px) {
      .solution__title-svg--promotion {
        height: 30.23px; } }

.solution__list {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.28em;
  line-height: 2.14286em; }
  @media screen and (max-width: 767px) {
    .solution__list {
      font-size: 11px; } }

.solution__block:not(:last-child) {
  margin-bottom: 39.26px; }
  @media screen and (max-width: 767px) {
    .solution__block:not(:last-child) {
      margin-bottom: 15px; } }

.solution__svg {
  width: 893.22px;
  left: 365.53px;
  top: 317.47px; }
  @media screen and (max-width: 767px) {
    .solution__svg {
      width: 370px;
      left: auto;
      right: -20px;
      top: 295px; } }

.contact {
  margin-bottom: 91.88px; }
  @media screen and (max-width: 767px) {
    .contact {
      margin-bottom: 50px; } }
  .contact__title {
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    height: 67.54px; }
    @media screen and (max-width: 767px) {
      .contact__title {
        height: 37.54px; } }
  .contact__text {
    font-size: 14px;
    line-height: 2.14286em;
    letter-spacing: 0.28em; }
    @media screen and (max-width: 767px) {
      .contact__text {
        font-size: 11px; } }
  .contact__form {
    margin-top: 35px; }
  .contact__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 767px) {
      .contact__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .contact__wrapper:not(:last-child) {
      margin-bottom: 17px; }
  .contact__label {
    font-size: 14px;
    letter-spacing: 0.28em;
    width: 159.83px;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
    @media screen and (max-width: 767px) {
      .contact__label {
        font-size: 11px;
        width: auto;
        margin-bottom: 5px; } }
  .contact__input {
    width: 721.73px;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .contact__input {
        width: 100%;
        font-size: 12px; } }
  .contact__input-text {
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border-radius: 0;
    border: 1px solid #949494; }
  .contact__textarea {
    resize: vertical;
    width: 100%;
    height: 230px;
    padding: 10px;
    border-radius: 0;
    border: 1px solid #949494; }
    .contact__textarea::-webkit-scrollbar {
      width: 8px; }
    .contact__textarea::-webkit-scrollbar-track {
      background: transparent; }
    .contact__textarea::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; }
  .contact__svg {
    width: 844.18px;
    left: -351.98px;
    top: -35.48px; }
    @media screen and (max-width: 767px) {
      .contact__svg {
        width: 400px;
        left: -100px; } }
  .contact__agreement {
    margin: 56px 0 18px 159.83px;
    width: 721.73px;
    height: 150px;
    overflow-y: scroll;
    border: 1px solid #949494;
    padding: 10.44px 0 14.47px 14.59px; }
    @media screen and (max-width: 767px) {
      .contact__agreement {
        width: 100%;
        margin: 36px 0 18px 0; } }
    .contact__agreement::-webkit-scrollbar {
      width: 8px; }
    .contact__agreement::-webkit-scrollbar-track {
      background: transparent; }
    .contact__agreement::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; }
  .contact__agreement-text {
    font-size: 12px;
    letter-spacing: 0.28em;
    line-height: 1.75em; }
    @media screen and (max-width: 767px) {
      .contact__agreement-text {
        font-size: 10px; } }
  .contact__agreement-paragraph:not(:last-child) {
    margin-bottom: 14px; }
    @media screen and (max-width: 767px) {
      .contact__agreement-paragraph:not(:last-child) {
        margin-bottom: 10px; } }
  .contact__agreement-checkbox {
    margin-left: 159.83px;
    font-size: 14px;
    letter-spacing: 0.28em; }
    @media screen and (max-width: 767px) {
      .contact__agreement-checkbox {
        margin-left: 0;
        font-size: 11px; } }
  .contact__checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #949494;
    margin-right: 10px;
    border-radius: 0; }
    @media screen and (max-width: 767px) {
      .contact__checkbox {
        width: 14px;
        height: 14px;
        margin-right: 7px; } }
  .contact__button-group {
    width: 721.73px;
    margin: 47px 0 0 159.83px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .contact__button-group {
        margin: 37px 0 0;
        width: 100%; } }
  .contact__button {
    line-height: 0;
    font-family: "A-OTF Shin Go Pro L", sans-serif;
    font-size: 14px;
    letter-spacing: 0.1em;
    border: 1px solid #949494;
    padding: 0 9px 3px;
    height: 25px;
    background-color: transparent;
    color: #949494;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .contact__button:not(:last-child) {
      margin-right: 10px; }
  .contact__thanks {
    display: block;
    text-align: center;
    padding-bottom: 0;
    letter-spacing: 0.28em;
    font-size: 24px; }
    @media screen and (max-width: 767px) {
      .contact__thanks {
        font-size: 16px; } }
  .contact__radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }

.footer {
  text-align: center;
  margin-bottom: 162.75px; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 0 3%;
      margin-bottom: 30px; } }
  .footer__info {
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 1.75em; }
  .footer__copyright {
    margin-top: 7.28px;
    display: block;
    font-size: 10px; }

.white {
  color: #fff; }

.white-bg {
  background: #fff; }

.black {
  color: #000; }

.black-bg {
  background: #000; }

.primary {
  color: #fff; }

.primary-bg {
  background: #fff; }

.blue .header__logo {
  background-color: #aad0ee; }

.blue .solution__title-svg,
.blue .will__action-text,
.blue .will__action-title,
.blue .section__title,
.blue .header__link--active .header__link-text,
.blue .header__link:hover .header__link-text,
.blue .will__action-info-svg,
.blue .will__action-item:hover .will__action-svg,
.blue .will__action-item--active .will__action-svg,
.blue .banner__svg .cls-1 {
  fill: #aad0ee !important; }

.blue .will__action-info-svg {
  stroke: #aad0ee; }

.blue .will__action-info-title {
  color: #aad0ee; }

.blue ul li::before {
  background-color: #aad0ee; }

.blue .section__svg {
  fill: #f2f8fc; }

.violet .header__logo {
  background-color: #b29ec7; }

.violet .solution__title-svg,
.violet .will__action-text,
.violet .will__action-title,
.violet .section__title,
.violet .header__link--active .header__link-text,
.violet .header__link:hover .header__link-text,
.violet .will__action-info-svg,
.violet .will__action-item:hover .will__action-svg,
.violet .will__action-item--active .will__action-svg,
.violet .banner__svg .cls-1 {
  fill: #b29ec7 !important; }

.violet .will__action-info-svg {
  stroke: #b29ec7; }

.violet .will__action-info-title {
  color: #b29ec7; }

.violet ul li::before {
  background-color: #b29ec7; }

.violet .section__svg {
  fill: #f7f5f9; }

.pink .header__logo {
  background-color: #da9399; }

.pink .solution__title-svg,
.pink .will__action-text,
.pink .will__action-title,
.pink .section__title,
.pink .header__link--active .header__link-text,
.pink .header__link:hover .header__link-text,
.pink .will__action-info-svg,
.pink .will__action-item:hover .will__action-svg,
.pink .will__action-item--active .will__action-svg,
.pink .banner__svg .cls-1 {
  fill: #da9399 !important; }

.pink .will__action-info-svg {
  stroke: #da9399; }

.pink .will__action-info-title {
  color: #da9399; }

.pink ul li::before {
  background-color: #da9399; }

.pink .section__svg {
  fill: #fbf4f5; }

.green .header__logo {
  background-color: #c1d349; }

.green .solution__title-svg,
.green .will__action-text,
.green .will__action-title,
.green .section__title,
.green .header__link--active .header__link-text,
.green .header__link:hover .header__link-text,
.green .will__action-info-svg,
.green .will__action-item:hover .will__action-svg,
.green .will__action-item--active .will__action-svg,
.green .banner__svg .cls-1 {
  fill: #c1d349 !important; }

.green .will__action-info-svg {
  stroke: #c1d349; }

.green .will__action-info-title {
  color: #c1d349; }

.green ul li::before {
  background-color: #c1d349; }

.green .section__svg {
  fill: #f9fbec; }

.orange .header__logo {
  background-color: #eabc00; }

.orange .solution__title-svg,
.orange .will__action-text,
.orange .will__action-title,
.orange .section__title,
.orange .header__link--active .header__link-text,
.orange .header__link:hover .header__link-text,
.orange .will__action-info-svg,
.orange .will__action-item:hover .will__action-svg,
.orange .will__action-item--active .will__action-svg,
.orange .banner__svg .cls-1 {
  fill: #eabc00 !important; }

.orange .will__action-info-svg {
  stroke: #eabc00; }

.orange .will__action-info-title {
  color: #eabc00; }

.orange ul li::before {
  background-color: #eabc00; }

.orange .section__svg {
  fill: #fdf8e5; }

.overflow {
  overflow: hidden; }

@media screen and (max-width: 767px) {
  .pc {
    display: none; } }

.sp {
  display: none; }
  @media screen and (max-width: 767px) {
    .sp {
      display: block; } }

.anchor {
  display: block;
  position: relative;
  top: -133.66px;
  visibility: hidden; }
  @media screen and (max-width: 767px) {
    .anchor {
      top: -60px; } }

.online {
  padding-top: 21px; }
  @media screen and (max-width: 767px) {
    .online {
      padding-top: 10px; } }

.mwform-checkbox-field label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.mw_wp_form .error {
  font-size: 12px !important; }

.mw_wp_form_confirm .contact__agreement-checkbox,
.mw_wp_form_confirm .contact__agreement {
  display: none; }

.mwform-radio-field:not(:last-child) {
  margin-right: 42px; }
  @media screen and (max-width: 767px) {
    .mwform-radio-field:not(:last-child) {
      margin-right: 22px; } }

.mwform-radio-field label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.mwform-radio-field-text {
  font-size: 14px;
  letter-spacing: 0.28em; }
  @media screen and (max-width: 767px) {
    .mwform-radio-field-text {
      font-size: 11px; } }

.mwform-radio-field input {
  width: 15.19px;
  height: 15.19px;
  margin: 6px 8.28px 0 0;
  border: 1px solid #949494; }
  @media screen and (max-width: 767px) {
    .mwform-radio-field input {
      width: 14px;
      height: 14px;
      margin: 2px 7px 0 0; } }

.mw_wp_form_complete {
  height: 560px; }
  @media screen and (max-width: 767px) {
    .mw_wp_form_complete {
      height: 245px; } }
